<template>
  <div>
    <b-form>
      <form-field 
        v-for="(field, name) in form.fields" 
        :form="form" 
        :key="name" 
        :name="name" 
        @input="onInput"
        @create="onCreate"
      />

      <b-button class="mr-3" @click="$emit('cancel')">Annuleer</b-button>
      <b-button variant="primary">Opslaan</b-button>
    </b-form>

    <h3 class="mt-3 mb-1">Rules:</h3>
    <b-table-simple small>
      <b-thead>
        <b-tr>
          <b-th>First period</b-th>
          <b-th>Last period</b-th>
          <b-th>Beneficiary</b-th>
          <b-th>Rule</b-th>
          <b-th>Share</b-th>
          <b-th>Split</b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <b-tr>

        </b-tr>
      </b-tbody>
    </b-table-simple>
  </div>
</template>

<script>

export default {
  name: "TrxMatchEdit",
  components: {
    'form-field': () => import('@/components/FormFieldV2'),
  },
  data() {
    return {
      form: {
        values: {},
        fields: {
          Name: {
            label: 'Name',
            type: 'text',
          },
        },
      },
    };
  },
  props: {
  },
  methods: {
    onInput (name, value) {
      const def = this.form.fields[name]
      console.log(value, name, def)

      if (value == '<create>' && def.create) {
        console.log('Create group')
        this.$bvModal.show('create-group')
        return
      }
    },
    onCreate (name) {
      console.log(name)
    },
  },
  watch: {
  },
  computed: {
    data: {
      get () {
        return ''
      },
      set () {

      }
    },
  },
  mounted() {
    /*
    this.$http.get('accounting/rule-groups', {
          params: { perPage: null }
        })
        .then(response => {

        })
        .catch(() => {
          this.$notify({
            type: 'danger',
            text: 'Er ging iets mis bij het laden'
          });
        });
      */
  }
};

</script>