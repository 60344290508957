var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-form",
        [
          _vm._l(_vm.form.fields, function(field, name) {
            return _c("form-field", {
              key: name,
              attrs: { form: _vm.form, name: name },
              on: { input: _vm.onInput, create: _vm.onCreate }
            })
          }),
          _c(
            "b-button",
            {
              staticClass: "mr-3",
              on: {
                click: function($event) {
                  return _vm.$emit("cancel")
                }
              }
            },
            [_vm._v("Annuleer")]
          ),
          _c("b-button", { attrs: { variant: "primary" } }, [_vm._v("Opslaan")])
        ],
        2
      ),
      _c("h3", { staticClass: "mt-3 mb-1" }, [_vm._v("Rules:")]),
      _c(
        "b-table-simple",
        { attrs: { small: "" } },
        [
          _c(
            "b-thead",
            [
              _c(
                "b-tr",
                [
                  _c("b-th", [_vm._v("First period")]),
                  _c("b-th", [_vm._v("Last period")]),
                  _c("b-th", [_vm._v("Beneficiary")]),
                  _c("b-th", [_vm._v("Rule")]),
                  _c("b-th", [_vm._v("Share")]),
                  _c("b-th", [_vm._v("Split")])
                ],
                1
              )
            ],
            1
          ),
          _c("b-tbody", [_c("b-tr")], 1)
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }